import type { ImageDetails } from '@constants/projects.types';
import React from 'react';

export const useCreateImageEmbedHook = () => {
    const createImageEmbed = (image: ImageDetails, imageClassName?: string) => {
        switch (image.type) {
            case 'svg': {
                return React.cloneElement(image.icon, {
                    className: imageClassName,
                });
            }
            case 'video': {
                return (
                    <video
                        src={image.url}
                        controls
                        autoPlay
                        muted
                        loop
                        playsInline
                        className={imageClassName}
                    />
                );
            }
            case 'image':
            default: {
                return (
                    <img
                        src={image.url}
                        alt={image.type === 'image' ? image.alt : ''}
                        loading="lazy"
                        className={imageClassName}
                    />
                );
            }
        }
    };

    return { createImageEmbed };
};
