import classNames from 'clsx';
import type { FC, PropsWithChildren } from 'react';
import css from './generic-section.module.scss';

interface GenericSectionProps {
    className?: string;
    contentClassName?: string;
    largePadding?: boolean;
}

export const GenericSection: FC<PropsWithChildren<GenericSectionProps>> = ({
    children,
    className,
    contentClassName,
    largePadding = true,
}) => {
    return (
        <section className={classNames(css.root, className)}>
            <div
                className={classNames(
                    css.content,
                    contentClassName,
                    largePadding && css.largePadding,
                )}
            >
                {children}
            </div>
        </section>
    );
};
