import type React from 'react';

export interface InfoSection {
    id: string;
    title?: string;
    description?: string | React.ReactElement;
    prefix?: string;
    highlightedTitle?: string;
}

export const InfoSections: { [key: string]: InfoSection } = {
    HOME_WHO_ARE_WE: {
        id: 'home-who-are-we',
        title: 'Wie zijn wij?',
        description:
            'AH Labs is de innovatieafdeling van Albert Heijn, waar nieuwe technologieën en ideeën snel en experimenteel worden verkend. Met een sterke focus op Generative AI werkt AH Labs op een agile manier aan het ontwikkelen, testen en valideren van innovatieve concepten. Bij succes, worden ideeën uitgerold in de praktijk. Dankzij deze aanpak kan Albert Heijn snel inspelen op nieuwe ontwikkelingen en klantbehoeften, wat zorgt voor een continue verbetering van zowel de winkel- als de digitale ervaring.',
    },
    HOME_SCAN_YOUR_RECIPE: {
        id: 'home-scan-your-recipe',
        title: 'Scan je recept',
        description:
            'Scan je recept is een functie waarmee klanten eenvoudig ingrediënten van recepten uit kookboeken, Instagram of blogs aan hun winkelwagentje bij Albert Heijn kunnen toevoegen. Met deze nieuwe optie kunnen klanten ook ingrediënten van andere bronnen scannen en toevoegen, waardoor het nog eenvoudiger wordt om hun boodschappenlijst samen te stellen.',
    },
    HOME_FOODSTEPS: {
        id: 'home-foodsteps',
        title: 'Klimaatbewuster koken',
        description:
            'Bij klimaatbewuster koken let je op de CO2-uitstoot van je maaltijden. Voor steeds meer Allerhande-recepten tonen we de geschatte CO2e-uitstoot, zodat klanten in één oogopslag de klimaatimpact van een gerecht kunnen zien. Dit maakt het makkelijker om te kiezen voor recepten met een lagere uitstoot, wat bijdraagt aan een duurzamere levensstijl.',
    },
    HOME_LEFTOVERS: {
        id: 'home-leftovers',
        title: 'Overblijvers',
        description:
            'Overblijvers richt zich op het verminderen van voedselverspilling door producten die niet verkocht zijn, zoals versproducten en andere artikelen met een korte houdbaarheid, aan te bieden tegen een gereduceerde prijs. Klanten kunnen hierdoor profiteren van aantrekkelijke kortingen op deze producten, terwijl Albert Heijn tegelijkertijd bijdraagt aan een duurzamer voedselbeleid. Dit initiatief moedigt klanten aan om bewust om te gaan met voedsel en biedt een praktische oplossing voor het verminderen van verspilling in de supermarkt.',
    },
    HOME_WAY_OF_WORKING: {
        id: 'home-way-of-working',
        title: 'Onze werkwijze',
        description:
            'Labs werkt met een Lean Start-up methode waarbij de nadruk ligt op snelle validatie van ideeën en continue klantcontact. Met onze vaardigheden kunnen we snel proof of concepts opzetten en valideren.',
    },
    HOME_SUGGESTIONS_CTA: {
        id: 'home-suggestions-cta',
        title: 'Heb jij nog leuke ideeën?',
        description:
            'Stuur ze zeker hieronder op en misschien wordt het wel ons volgende project',
    },
    WOW_HEADING: {
        id: 'wow-heading',
        title: 'Supersnelle validatie van innovatieve concepten',
    },
    IAL_HEADING: {
        id: 'ial-heading',
        title: 'Werk mee aan laatste innovaties bij',
        highlightedTitle: 'Albert Heijn Labs',
    },
    ILA_INTERESTED: {
        id: 'ila-interested',
        title: 'Interesse?',
        description:
            'Kijk of er momenteel vacatures open staan voor de bovenstaande stages.',
    },
};
