import { GenericSection } from '@components/elements/generic-section/generic-section';
import type { ImageDetails } from '@constants/projects.types';
import type { InfoSection as InfoSectionData } from '@enums/info-sections';
import { useCreateImageEmbedHook } from '@hooks/use-create-image-embed-book';
import { ArrowRight24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah';
import {
    RegularButton,
    RegularButtonText,
} from '@royalaholddelhaize/design-system-pantry-web/components/button/regular-button/regular-button';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import classNames from 'clsx';
import Link from 'next/link';
import React, { type FC } from 'react';
import css from './info-section.module.scss';

export declare type InfoSectionStyle = 'default' | 'ah-dark' | 'ah-light';
export declare type InfoSectionTextSize = 'default' | 'medium' | 'large';
export declare type InfoSectionImageSplit = 'half' | 'third';
export declare type InfoSectionTextColor = 'primary' | 'secondary';

export interface InfoSectionCta {
    /**
     * The action to perform when the CTA is clicked.
     * String for external links, function for action.
     */
    action: string | (() => void);
    target?: string;
    label?: string;
    icon?: boolean;
}

export interface InfoSectionProps {
    section: InfoSectionData;
    image: ImageDetails;
    className?: string;
    cta?: InfoSectionCta;
    imagePosition?: 'left' | 'right';
    smallImagePosition?: 'top' | 'bottom' | 'hidden';
    theme?: InfoSectionStyle;
    imageClassName?: string;
    textSize?: InfoSectionTextSize;
    titleSize?: InfoSectionTextSize;
    imageSplit?: InfoSectionImageSplit;
    titleGap?: boolean;
    largePadding?: boolean;
    textColor?: InfoSectionTextColor;
    centerImage?: boolean;
}

export const InfoSection: FC<InfoSectionProps> = ({
    section,
    image,
    className,
    cta,
    imageClassName,
    imagePosition = 'right',
    smallImagePosition = 'top',
    theme = 'default',
    textSize = 'default',
    imageSplit = textSize === 'large' ? 'third' : 'half',
    titleSize = 'default',
    textColor = 'primary',
    largePadding,
    titleGap = true,
    centerImage = false,
}) => {
    const { createImageEmbed } = useCreateImageEmbedHook();

    const rootClass = classNames(
        css.root,
        theme === 'ah-dark' && css.ahDark,
        theme === 'ah-light' && css.ahLight,
        textSize === 'large' && css.largeText,
        imageSplit === 'third' && css.thirdImageSplit,
    );

    const contentClass = classNames(
        css.content,
        className,
        imagePosition === 'left' && css.contentLeft,
        smallImagePosition === 'bottom' && css.smallImageBottom,
    );

    const descriptionClass = classNames(
        css.descriptionContainer,
        titleGap && css.descriptionGap,
    );

    const description = () => {
        const classes = [
            css.description,
            textColor === 'secondary' && css.secondaryTextColor,
        ];
        if (typeof section.description === 'string') {
            return (
                <Typography className={classNames(...classes)}>
                    {section.description}
                </Typography>
            );
        }

        const element = section.description as React.ReactElement<{
            className?: string;
        }>;
        return React.cloneElement(element, {
            className: classNames(...classes, element.props.className),
        });
    };

    const handleCtaClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (cta == null || typeof cta.action !== 'function') {
            return;
        }

        event.preventDefault();
        cta.action();
    };

    return (
        <GenericSection
            className={rootClass}
            contentClassName={contentClass}
            largePadding={largePadding}
        >
            <div className={descriptionClass}>
                {section.prefix && (
                    <Typography className={css.prefix}>
                        {section.prefix}
                    </Typography>
                )}
                {section.title && (
                    <Typography
                        variant="display"
                        as="h2"
                        className={classNames(
                            css.title,
                            titleSize === 'large' && css.titleLarge,
                            titleSize === 'medium' && css.titleMedium,
                        )}
                    >
                        {section.title}
                        <br />
                        {section.highlightedTitle && (
                            <span
                                className={classNames(
                                    css.highlightedTitle,
                                    titleSize === 'large' && css.titleLarge,
                                    titleSize === 'medium' && css.titleMedium,
                                )}
                            >
                                {section.highlightedTitle}
                            </span>
                        )}
                    </Typography>
                )}
                {section.description && description()}

                {cta &&
                    (typeof cta.action === 'string' ? (
                        <Link
                            href={cta.action as string}
                            target={cta.target ?? '_blank'}
                            className={css.cta}
                        >
                            <RegularButtonText>
                                {cta.label ?? 'Lees meer'}
                            </RegularButtonText>
                            {(cta.icon ?? true) && <ArrowRight24Icon />}
                        </Link>
                    ) : (
                        <RegularButton
                            onClick={
                                typeof cta.action === 'function'
                                    ? handleCtaClick
                                    : undefined
                            }
                            className={css.cta}
                        >
                            <RegularButtonText>
                                {cta.label ?? 'Lees meer'}
                            </RegularButtonText>
                            {(cta.icon ?? true) && <ArrowRight24Icon />}
                        </RegularButton>
                    ))}
            </div>
            <div
                className={classNames(
                    css.imageContainer,
                    centerImage && css.centeredImage,
                    smallImagePosition === 'hidden' && css.smallImageHidden,
                )}
            >
                {createImageEmbed(image, imageClassName)}
            </div>
        </GenericSection>
    );
};
