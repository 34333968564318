'use client';

import { HomeSuggestionsIllustrationIcon } from '@assets/home-suggestions-illustration';
import {
    InfoSection,
    type InfoSectionStyle,
    type InfoSectionTextSize,
} from '@components/elements/info-section/info-section';
import { ContactPopup } from '@components/features/contact-popup/contact-popup';
import { InfoSections } from '@enums/info-sections';
import { type FC, useState } from 'react';

interface IdeasSectionProps {
    theme?: InfoSectionStyle;
    textSize?: InfoSectionTextSize;
}

export const IdeasSection: FC<IdeasSectionProps> = ({
    theme,
    textSize = 'default',
}) => {
    const [modalState, setModalState] = useState(false);

    const switchModal = () => {
        setModalState(!modalState);
    };

    return (
        <>
            <InfoSection
                section={InfoSections.HOME_SUGGESTIONS_CTA}
                image={{
                    icon: <HomeSuggestionsIllustrationIcon />,
                    type: 'svg',
                }}
                titleSize="medium"
                textSize={textSize}
                textColor="secondary"
                smallImagePosition="bottom"
                largePadding={false}
                theme={theme}
                cta={{
                    action: () => setModalState(!modalState),
                    label: 'Stuur je idee!',
                    icon: false,
                }}
            />

            <ContactPopup isOpen={modalState} setOpenAction={switchModal} />
        </>
    );
};
